@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
}

.app {
    display: flex;
    flex-direction: column;
    background-color:  #FDE2F3;
    /* height: 100svh;
    overflow-y: scroll; */
    /* border: 2px solid red; */
}

body::-webkit-scrollbar {
    display: none;
}

.redirect_greetsu {
    position: fixed;
    /* border: 2px solid red; */
    bottom: 16px;
    left: 48px;
    /* height: 40px; */
    display: flex;
    align-items: center;
    gap: 8px;
    text-decoration: none;
}

.redirect_greetsu:visited,
.redirect_greetsu:focus,
.redirect_greetsu:active {
  color: #141414;
}

.redirect_greetsu img {
    height: 100%;
}

.redirect__text .text__top {
    font-size: 16px;
    font-weight: 400;
    font-family: "Inter";
    margin-left: 8px;
    margin-bottom: -8px;
}

.redirect__text .text__bottom {
    font-size: 24px;
    font-weight: 600;
    font-family: "Inter";
    font-size: 2rem;
    color: #2A2F4F;
}


/* Media Queries */
@media screen and (max-width: 1024px) {
    .redirect_greetsu {
        display: none;
    }
}


@media screen and (max-width: 600px) {
    * {
        font-size: 10.5px;
    }
}

@media screen and (min-width: 600px) and (max-width: 1025px) {
    * {
        font-size: 15px;
    }
}