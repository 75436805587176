header {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    padding-left: 1rem;
    height: 6rem;
    position: sticky;
    /* background-color: #fff; */
    border-bottom: 1px solid #eaeaea;
    background-color: #E5BEEC;
}

.header-logo {
    text-decoration: none;
    width: 10%;
    max-width: 80px;
}

.header-img {
    width: 100%;
    /* max-width: 80px; */
}

.heading-txt {
    font-size: 1.5rem;
    /* padding: 0.7rem; */
    color: #2A2F4F;
    font-style: italic;
}

.heading-subtext {
    position: absolute;
    font-size: 0.5rem;
    top: 100%;
    left: 0;
    text-wrap: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
    align-self: flex-end;
    color: #000;
    z-index: 5;
}


/* Media Queries */
@media  screen and (max-width: 768px) {
    .heading-subtext img {
        height: 15px;
    }
}