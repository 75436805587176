.form-container {
    width: 100%;
    display: flex;
    justify-content: center;
    /* min-height: 50px; */
    min-height: 100vh;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 2rem;
    padding-top: 1rem;
    margin: auto;
    background-color:  #FDE2F3;
}

.section-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   padding: 0 2rem 1rem 2rem;
    margin: auto;
    background-color:  #FDE2F3;
    width: 35%;
    border: dashed 2px #917FB3;
    border-radius: 15px;
}


h2{
    font-size: 2rem;
    padding: 0.7rem;
    color: #2A2F4F;
}

label{
    display: flex;
    flex-direction: column;
    width: 35%;
    font-size: 1.2rem;
    padding-top: 1rem;
    margin-bottom: 0.5rem;
}

.section-info > label{
    width: 100%;

}

input {
    height: 6vh;
    border-radius: 8px;
    border: none;
    padding-left: 1rem;
    margin-top: 0.4rem;
    background-color: #E5BEEC;
}

.hidden-inpt {
    height: 6vh;
    border-radius: 8px;
    border: dashed 2px #917FB3;
    margin-top: 0.4rem;
    background-color: #E5BEEC;
    transition: 0.2s ease-in-out;
}

.hidden-inpt-again{
    height: 5vh;
    width: 25%;
    cursor: pointer;
    color:  #E5BEEC;
}

.hidden-inpt-again::file-selector-button{
    height: 5vh;
    border: none;
    background-color: #E5BEEC;
    cursor: pointer;
}

.date{
    padding-right: 1rem;
}

.date:hover {
    cursor: pointer;
}

.hidden-inpt-again::file-selector-button:hover{
    opacity: 0.6;
}

.hidden-inpt::file-selector-button {
    height: 6vh;
    width: 100%;
    border: none;
    background-color: #E5BEEC;
    cursor: pointer;
}

.hidden-inpt::file-selector-button:hover {
   opacity: 0.6;
}


.show-file {
    height: 6vh;
    border-radius: 8px;
    border: dashed 2px #917FB3;
    margin-top: 0.4rem;
    background-color: #E5BEEC;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.img-spec{
    display: flex;
    align-items: baseline;
    column-gap: 6px;
}

.field-txt {
    font-size: 1.2rem;
}

span {
    font-size: 0.95rem;
    opacity: 0.5;
}

button {
    /* width: 14%; */
    height: 5.4vh;
    border-radius: 8px;
    border: none;
    margin-top: 2rem;
    background-color: #FDE2F3;
    color: #FDE2F3;
    background-color: #917FB3;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    padding: 1rem 1rem 2rem 1rem;
}

/* .section-Milestone > button{
    width: 58%;
} */

button:hover {
    background-color: #1b1e30;
    color: #FDE2F3;
}

button:active {
    opacity: 0.6;
    color: #FDE2F3;
}

.section-Milestone{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 35%;
    border: dashed 2px #917FB3;
    border-radius: 15px;
    margin-top: 1rem;
    padding-right: 2rem;
    padding-left: 2rem;

}

.event {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-top: 1rem;
    padding-right: 2rem;
    padding-left: 2rem;
}

.event >label {
   
    width: 45%;
    font-size: 1rem;
    padding-top: 1rem;
    margin-bottom: 0.5rem;
}

 .event > input{
    /* width: 100%; */
 }




  .search {
    position: relative;
  }
  
  .search-btn {
    position: absolute;
    left: 25px;
    top: 22px;
    font-size: 1.5rem;
}

  

  
  .search-list::-webkit-scrollbar {
    width: 5px;
  }
  

  
  .search-list > li:hover,
  .search-list > li:focus {
    background-color: #E5BEEC;
  }

 

  .event-dropdown {
    background-color: #E5BEEC;
    border: 1px solid #000000;
    box-shadow: 0px 8px 20px rgba(24, 24, 24, 0.08);
    border-radius: 5px;
    width: 16%;
    padding: 2px;
    position: absolute;
    margin-top: 240px;
    margin-right: 19%;
    background-color: #FDE2F3;
    height: 140px;
}

.search > input {
    padding-left: 55px;
    width: 70%;
    border: 1px solid #d9d9d9;
    outline: none;
    color: #121212;
    font-family: var(--ff-gilroy);
    font-weight: 400;
    font-size: 1rem;
    margin-left: 2%;
    background-color: #E5BEEC;
}

.search-list {
    margin-top: 10px;
    margin-bottom: 0;
    padding: 0px;
    list-style-type: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 2px;
    max-height: 80px;
    overflow-y: auto;
}

.search-list > li {
    font-family: var(--ff-gilroy);
    font-weight: 500;
    font-size: 1rem;
    line-height: 24px;
    color: #000000;
    cursor: pointer;
    width: auto;
    height: 30px;
    border-radius: 4px;
    transition: background-color 0.5s ease;
    padding: 0 15px;
    margin-left: 10px;
}

.create-btn{
    width: 100%;
    display: flex;
    justify-content: center;
}

.delete-milestone{
    cursor: pointer;
   
    position: absolute;
    right: 1rem;
    top: 1rem;
}

@media screen and (max-width: 600px) {

    .section-info{
        width: 80%;
    }
    .section-info > label {
        width: 100%;
    }

    input {
        height: 4.8vh;
    }

    .hidden-inpt {
        height: 4.8vh;
    }

    .hidden-inpt::file-selector-button {
        height: 4.8vh;
    }

    .hidden-inpt-again {
        height: 3.8vh;
    }

    .hidden-inpt-again::file-selector-button{
        height: 3.8vh;
    }
    
    .show-file {
        height: 4.8vh;
    }

    .section-Milestone {
        width: 80%;
        /* column-gap: 2.4rem; */

    }
    .event > label {
        width: 45%;
    }

  

    button {
        /* width: 30%; */
        height: 3.2vh;
    }

    .event-dropdown {
        width: 37%;
        margin-right: 40%;
        margin-top: 220px;
    }

    .search > input {
        width: 55%;
    }

    .search-btn {
        top: 2rem;
        font-size: 1.8rem;
    }

}

@media screen and (min-width: 600px) and (max-width: 1023px) {

    .section-info{
        width: 60%;
    }
    .section-info>label {
        width: 100%;
    }

    input {
        height: 5.5vh;
    }

    .hidden-inpt {
        height: 5.5vh;
    }

    .hidden-inpt::file-selector-button {
        height: 5.5vh;
    }

    .hidden-inpt-again {
        height: 4.5vh;
    }

    .hidden-inpt-again::file-selector-button{
        height: 4.5vh;
    }
    
    .show-file {
        height: 5.5vh;
    }

    .section-Milestone {
        width: 60%;
        /* column-gap: 2.4rem; */
    }
    .event > label {
        width: 45%;
    }

    .event-dropdown {
        width: 28%;
        margin-right: 30.5%;
    }
    .search > input {
        width: 65%;
    }

    button {
        /* width: 30%; */
        height: 5.5vh;
    }
}

@media screen and (width: 1024px) {

    .section-info{
        width: 55%;
    }
     .section-info >label {
        width: 100%;
    }

    input {
        height: 7vh;
    }

    .hidden-inpt {
        height: 7vh;
    }

    .hidden-inpt::file-selector-button {
        height: 7vh;
    }

    .hidden-inpt-again {
        height: 6vh;
    }

    .hidden-inpt-again::file-selector-button{
        height: 6vh;
    }
    
    .show-file {
        height: 7vh;
    }

    .section-Milestone {
        width: 55%;
        /* column-gap: 2.4rem; */
    }

    

    .event-dropdown {
        width: 26%;
        margin-right: 28%;
    }

    button {
        /* width: 30%; */
        height: 7vh;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1435px) {

    .search > input {
        width: 55%;
    }
}


@media screen and (width: 280px) {

    .section-info{
        width: 90%;
    }
    .section-info >label {
        width: 100%;
    }

    input {
        height: 4.8vh;
    }

    .hidden-inpt {
        height: 4.8vh;
    }

    .hidden-inpt::file-selector-button {
        height: 4.8vh;
    }

    .hidden-inpt-again {
        height: 3.8vh;
    }

    .hidden-inpt-again::file-selector-button{
        height: 3.8vh;
    }
    
    .show-file {
        height: 4.8vh;
    }

    .section-Milestone {
        width: 90%;
        /* column-gap: 2.4rem; */
    }

    button {
        /* width: 37%; */
        height: 5.2vh;
    }
}
