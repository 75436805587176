.img-1 {
    width: 18%;
    object-fit: cover;
    height: 12vh;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    border-radius: 50%;
    border: 2.4px solid white;
}

.img-left{
    width: 50%;
    height: 17vh;
    object-fit: cover;
    border-top-left-radius: 50% ;
    border-left:4px solid #D01760;
    border-top: 4px solid #D01760;
    border-bottom: 4px solid #D01760;
    border-bottom-left-radius: 50%;
}

.img-right{
    width: 50%;
    height: 17vh;
    object-fit: cover;
    border-right:4px solid #D01760;
    border-top: 4px solid #D01760;
    border-bottom: 4px solid #D01760;
    border-top-right-radius: 50% ;
    border-bottom-right-radius: 50%;
}

.cple-img {
    width: 100%;
    height: 17vh;
    object-fit: cover;
    border: 4px solid #D01760;
    
    border-radius: 35%;
}

.timeline-main {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 6rem);
}

.timeline-compo {
    display: flex;
    margin: auto;
    row-gap: 0.2rem;
    width: 40%;
    flex-direction: column;
    align-items: center;
    background-color: #FDE2F3;
    padding-bottom: 1.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1.5rem;
    overflow-x: hidden;
    background-image: url('Group 53453.svg'),
        url('Group 53456.svg'),
        url('Group 53458.svg'),
        url('Group 53454.svg'),
        url('Group 53460.svg'),
        url('Group 53453.svg'),
        url('Group 53454.svg'),
        url('Group 53460.svg'),
        url('Group 53453.svg'),
        url('Group 53460.svg'),
        url('Group 53453.svg'),
        url('Group 53456.svg'),
        url('Group 53454.svg'),
        url('Group 53460.svg'),
        url('Group 53453.svg'),
        url('Group 53453.svg'),
        url('Group 53460.svg'),
        url('Group 53453.svg'),
        url('Group 53453.svg'),
        url('Group 53456.svg'),
        url('Group 53458.svg'),
        url('Group 53454.svg'),
        url('Group 53460.svg'),
        url('Group 53453.svg');
    background-repeat: no-repeat;
    background-position: 5% 9% , 10% 23% , 29% 33%, 43% 31%, 69% 49% , 27% 52% , 58% 61% , 72% 63% , 38% 81% , 88% 58%, 82% 89% , 37% 38% , 90% 17%, 34% 59%, 63% 42% , 60% 50% , 50% 60% , 75% 70% , 35% 80% , 30% 90%, 75% 8% , 30% 20% , 70% 10%, 35% 30%, 75% 40% , 20% 50% , 50% 60% , 45% 70% , 20% 80% , 30% 90%, 5% 8% , 30% 20% , 10% 10%, 35% 30%, 75% 40% , 20% 50% , 50% 60% , 75% 70% , 35% 80% , 60% 350%, 70% 8% , 80% 20% , 90% 10%, 35% 30%, 75% 40% , 22% 50% , 50% 60% , 75% 70% , 41% 80% , 33% 92%, 5% 8% , 30% 20% , 10% 12%, 35% 30%, 75% 40% , 33% 34% , 90% 90% ,  41% 80% , 33% 92%, 67% 12% , 80% 70% , 78% 77%, 65% 13%, 75% 80% , 23% 94%;
    
}
.canvas {
    display: flex;
    row-gap: 0.2rem;
    /* width: 100%; */
    width: 85%;
    flex-direction: column;
    align-items: center;
    background-color: #FDE2F3;
    padding-bottom: 1.5rem;
    padding-right: 2rem;
    padding-top: 1.5rem;
    padding-left: 5%;
}

.upper-div{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 10vh;
    background-color: #D01760;
    padding: 1rem;
    margin-bottom: 0.5rem;
    border-radius: 130px;
}

.name-text{
    font-size: 1.3rem;
    color: #FDE2F3;
    font-weight: bold;
    padding-left: 1rem;
    padding-right: 1rem;
}

.left-div {
    display: flex;
    align-items: center;
    column-gap: 0.4rem;
    text-align: left;
    width: 100%;
    padding-left: 10rem;
    border-radius: 130px;
}

.right-div {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    column-gap: 0.4rem;
    width: 100%;
    padding-right: 10rem;
    border-radius: 130px;
}

.left-event-txt {
    font-size: 0.9rem;
    font-weight: 600;
    color: #2A2F4F;
}

.svg-left-right {
    width: 65%;
    margin-top: -1rem;
}

.main-img{
    display: flex;
    justify-items: auto;
    width: 25%;
    height: 17vh;
    margin-left: -2rem;
    border-radius: 50%;
    
}

.left-div-main{
    display: flex;
    align-items: center;
    column-gap: 1.2rem;
    text-align: left;
    width: 40%;
    padding-left: 8rem;
    margin-top: -1rem;
    border-radius: 130px;
}

.align{
    display: flex;
    flex-direction: column;
    row-gap: 0.3rem;
}

.main-text {
    font-weight: 600;
    color: #D01760;
    font-size: 1rem;
}

.main-text-align {
    font-weight: 600;
    color: #D01760;
    font-size: 1rem;
    margin-left: 1rem;
}

.loveto-text {
    color: #2A2F4F;
    font-weight: 500;
    margin-left: 1rem;
}

.share-link{
    
        display: flex;
        width: 20.0625rem;
        padding: 0rem 0.625rem;
        justify-content: center;
        align-items: center;
        gap: 1.375rem;
      
}

.share-link >button{
    background-color: transparent;
    border: none;
    padding: 0px;
    font: inherit;
    color: inherit;
    cursor: pointer;
    height: 80px;
    width: 80px;
}


.download-btn{
    all: unset;
    display: flex;
    height: 1.875rem;
    justify-content: center;
    align-items: center;
    gap: .75rem;
    border-radius: .375rem;
    background: #000;
    cursor: pointer;
    margin-top: 3rem;
    padding: 5px;
    color: white;
    width: 100%;
    
}

.back-btn {
    all: unset;
    display: flex;
    height: 1.875rem;
    justify-content: center;
    align-items: center;
    gap: .75rem;
    border-radius: .375rem;
    background: transparent;
    cursor: pointer;
    margin-top: 1rem;
    border: 2px solid #000;
    border-radius: 0.35rem;
    margin-bottom: 3rem;
    padding: 2.5px;
    color: black;
    width: 100%;
}

.Toastify__toast-container {
    height: 100px !important; /* Customize the height as needed */
}


@media screen and (max-width: 600px) {
    .timeline-compo {
        width: 85%;
    }

    .upper-div{
        height: 7vh;
    }

    .img-1 {
        height: 7.5vh;
    }

    .img-left {
        height: 10vh;
        border-left:3px solid #D01760;
        border-top: 3px solid #D01760;
        border-bottom: 3px solid #D01760;
    }

    .img-right {
        height: 10vh;
        border-right: 3px solid #D01760;
        border-top: 3px solid #D01760;
        border-bottom: 3px solid #D01760;
    }

    .right-div {
    
        padding-right: 5rem;
        border-radius: 130px;
        margin-bottom: -1rem;
        margin-top: -1rem;
    }
    
    .left-div {
       
        padding-left: 5rem;
        border-radius: 130px;
        margin-top: -1rem;
        margin-bottom: -1rem;
    }
    
    .cple-img {
            height: 12vh;
            margin-top: 3rem;
    }
   

}

@media screen and (min-width: 600px) and (max-width: 1023px) {
    .timeline-compo {
        width: 80%;
    }

    .upper-div{
        height: 8vh;
    }

    .img-1 {
        height: 9vh;
        width: 17%;
    }

    .img-left {
        height: 10vh;
        border-left:3px solid #D01760;
        border-top: 3px solid #D01760;
        border-bottom: 3px solid #D01760;
    }

    .img-right {
        height: 10vh;
        border-right: 3px solid #D01760;
        border-top: 3px solid #D01760;
        border-bottom: 3px solid #D01760;
    }
    
}

@media screen and (width: 1024px) {
    .timeline-compo {
        width: 70%;
    }

    .upper-div{
        height: 10vh;
    }

    .img-1 {
        height: 12vh;
        width: 13%;
    }

    .img-left {
        height: 15vh;
        border-left:3px solid #D01760;
        border-top: 3px solid #D01760;
        border-bottom: 3px solid #D01760;
    }

    .img-right {
        height: 15vh;
        border-right: 3px solid #D01760;
        border-top: 3px solid #D01760;
        border-bottom: 3px solid #D01760;
    }
}

@media screen and (width: 280px) {
    .timeline-compo {
        width: 88%;
    }

    .upper-div{
        height: 7vh;
    }

    .img-1 {
        height: 7.5vh;
    }

    .img-left {
        height: 10vh;
        border-left:3px solid #D01760;
        border-top: 3px solid #D01760;
        border-bottom: 3px solid #D01760;
    }

    .img-right {
        height: 10vh;
        border-right: 3px solid #D01760;
        border-top: 3px solid #D01760;
        border-bottom: 3px solid #D01760;
    }
}
